import React, { useState } from "react";

const Quoatation = () => {
  const [invoiceDetails, setInvoiceDetails] = useState({
    items: [
      { id: 1, name: "Item 1", price: 100, quantity: 2 },
      { id: 2, name: "Item 2", price: 150, quantity: 1 },
    ],
    gstRate: 18,
  });

  // Calculate subtotal
  const subtotal = invoiceDetails.items.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  // Calculate GST amount
  const gstAmount = (subtotal * invoiceDetails.gstRate) / 100;

  // Calculate total
  const total = subtotal + gstAmount;

  return (
    <div className="bill-template" id="tabelcontent">
      <h1 className="text-center">Quoatation</h1>
      <div className="bill-header">
        <div>
          <h2>Company Name</h2>
          <p>Address Line 1</p>
          <p>Address Line 2</p>
          <p>GSTIN: 1234567890</p>
        </div>
        <div>
          <h3>Customer Name</h3>
          <p>Customer Address</p>
        </div>
      </div>

      <table className="bill-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Item</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {invoiceDetails.items.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.quantity}</td>
              <td>₹{item.price.toFixed(2)}</td>
              <td>₹{(item.price * item.quantity).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="bill-summary">
        <p>Subtotal: ₹{subtotal.toFixed(2)}</p>
        <p>
          GST ({invoiceDetails.gstRate}%): ₹{gstAmount.toFixed(2)}
        </p>
        <p>
          <strong>Total: ₹{total.toFixed(2)}</strong>
        </p>
      </div>

      <div className="bill-footer">
        <p>Thank you for your business!</p>
      </div>
    </div>
  );
};

export default Quoatation;
