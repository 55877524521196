import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2"; // Import SweetAlert2

const UpdateCustomer = () => {
  const [customerName, setCustomerName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [deliveryName, setDeliveryName] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryGstNumber, setDeliveryGstNumber] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { id } = useParams(); // Get the customer ID from URL parameters

  useEffect(() => {
    const fetchCustomer = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await axios.get(
          `https://bwsoft.in/api/customer/update/${id}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const customer = response.data;
        setCustomerName(customer.name);
        setEmail(customer.email);
        setMobile(customer.mobile);
        setAddress(customer.address);
        setGstNumber(customer.customer_gst_no);
        setDeliveryName(customer.delivery_name);
        setDeliveryAddress(customer.delivery_address);
        setDeliveryGstNumber(customer.delivery_gst_no);
      } catch (error) {
        console.error("Error fetching customer:", error);
        setError("Failed to fetch customer data.");
      }
    };

    fetchCustomer();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const customerData = {
      name: customerName,
      email,
      mobile,
      address,
      customer_gst_no: gstNumber,
      delivery_name: deliveryName,
      delivery_address: deliveryAddress,
      delivery_gst_no: deliveryGstNumber,
    };

    const token = localStorage.getItem("token");

    try {
      await axios.put(
        `https://bwsoft.in/api/customer/update/${id}/`,
        customerData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Show success alert
      Swal.fire({
        title: "Success!",
        text: "Customer updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/manage-customers"); // Redirect after successful update
      });
    } catch (error) {
      console.error("Error updating customer:", error);
      setError("Failed to update customer. Please try again.");

      // Show error alert
      Swal.fire({
        title: "Error!",
        text: "Failed to update customer. Please check your input and try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div className="container mt-4" id="tabelcontent">
      <h2>Update Customer</h2>
      {error && <p className="text-danger">{error}</p>}
      <Form onSubmit={handleSubmit}>
        {/* Customer Information */}
        <h4>Customer Information</h4>
        <Form.Group controlId="formCustomerName">
          <Form.Label>Name *</Form.Label>
          <Form.Control
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email Address *</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formMobile">
          <Form.Label>Mobile Number *</Form.Label>
          <Form.Control
            type="text"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formAddress">
          <Form.Label>Address</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formGstNumber">
          <Form.Label>GST Number</Form.Label>
          <Form.Control
            type="text"
            value={gstNumber}
            onChange={(e) => setGstNumber(e.target.value)}
          />
        </Form.Group>

        {/* Delivery Information */}
        <h4>Customer Delivery Address</h4>
        <Form.Group controlId="formDeliveryName">
          <Form.Label>Delivery Name *</Form.Label>
          <Form.Control
            type="text"
            value={deliveryName}
            onChange={(e) => setDeliveryName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formDeliveryAddress">
          <Form.Label>Delivery Address *</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={deliveryAddress}
            onChange={(e) => setDeliveryAddress(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formDeliveryGstNumber">
          <Form.Label>Delivery GST Number</Form.Label>
          <Form.Control
            type="text"
            value={deliveryGstNumber}
            onChange={(e) => setDeliveryGstNumber(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Update Customer
        </Button>
      </Form>
    </div>
  );
};

export default UpdateCustomer;
