import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { createCategory, setAuthToken } from "./Apiservice"; // Adjust the path as necessary

const AddCategory = () => {
  const [categoryName, setCategoryName] = useState("");
  const [status, setStatus] = useState("active");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("Retrieved token:", token);
    setAuthToken(token);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newCategory = {
      name: categoryName,
      status: status === "active",
    };

    try {
      console.log("Submitting with token:", localStorage.getItem("token"));
      const response = await createCategory(newCategory);
      console.log("Category created:", response);
      setCategoryName(""); // Clear the category name
      setStatus("active");
      setSuccessMessage("Category created successfully!");
      // Reset states...
    } catch (err) {
      console.error("Error creating category:", err);
      console.log("Response data:", err.response?.data); // Log the error response
      setError(
        err.response?.data?.detail || "An error occurred. Please try again."
      );
      // Reset success message...
    }
  };

  return (
    <div className="container mt-4" id="tabelcontent">
      <h2>Create Category</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      {successMessage && (
        <div className="alert alert-success">{successMessage}</div>
      )}
      <Form onSubmit={handleSubmit}>
        {/* Category Name */}
        <Form.Group className="mb-3">
          <Form.Label>Category Name *</Form.Label>
          <Form.Control
            type="text"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            placeholder="Enter category name"
            required
          />
        </Form.Group>

        {/* Status */}
        <Form.Group className="mb-3">
          <Form.Label>Status *</Form.Label>
          <Form.Control
            as="select"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            required
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </Form.Control>
        </Form.Group>

        <Button variant="primary" type="submit">
          Create Category
        </Button>
      </Form>
    </div>
  );
};

export default AddCategory;
