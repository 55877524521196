import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button, Alert, Spinner } from "react-bootstrap";

const ProductEdit = () => {
  const { productId } = useParams(); // Get product ID from the URL
  const navigate = useNavigate();

  const [product, setProduct] = useState({
    name: "",
    code: "",
    image: "",
    purchase_price: "",
    retail_price: "",
    inventory_qty: "",
    status: false,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Access token retrieval (assuming it's stored in local storage)
  const accessToken = localStorage.getItem("token");

  // Fetch product details by ID
  useEffect(() => {
    if (!productId) {
      setError("Product ID is not available.");
      setLoading(false);
      return; // Exit if productId is undefined
    }
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `https://bwsoft.in/api/product/update/${productId}/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setProduct(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load product details");
        setLoading(false);
      }
    };
    fetchProductDetails();
  }, [productId, accessToken]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle form submission to update product
  // Handle form submission to update product
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure all numeric fields are valid
    if (
      product.purchase_price < 0 ||
      product.retail_price < 0 ||
      product.inventory_qty < 0
    ) {
      setError("Price and quantity must be positive numbers.");
      return;
    }

    setLoading(true);
    try {
      await axios.put(
        `https://bwsoft.in/api/product/update/${productId}/`, // Use the correct URL with productId
        product,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Include the token in the headers
          },
        }
      );
      setSuccess(true);
      navigate("/manage-products"); // Redirect to manage products page
    } catch (err) {
      console.error("Error updating product:", err); // Log the error for debugging
      setError(
        err.response ? err.response.data.message : "Failed to update product"
      );
    } finally {
      setLoading(false); // Always set loading to false after operation
    }
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status" />
        <span>Loading...</span>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div className="container mt-4" id="tabelcontent">
      <h2>Edit Product</h2>
      {success && (
        <Alert variant="success">Product updated successfully!</Alert>
      )}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="name" className="mb-3">
          <Form.Label>Product Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={product.name}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="code" className="mb-3">
          <Form.Label>Product Code</Form.Label>
          <Form.Control
            type="text"
            name="code"
            value={product.code}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="category" className="mb-3">
          <Form.Label>Category</Form.Label>
          <Form.Control
            type="text"
            name="category"
            value={product.category}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="purchase_price" className="mb-3">
          <Form.Label>Purchase Price</Form.Label>
          <Form.Control
            type="number"
            name="purchase_price"
            value={product.purchase_price}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="retail_price" className="mb-3">
          <Form.Label>Retail Price</Form.Label>
          <Form.Control
            type="number"
            name="retail_price"
            value={product.retail_price}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="inventory_qty" className="mb-3">
          <Form.Label>Inventory Quantity</Form.Label>
          <Form.Control
            type="number"
            name="inventory_qty"
            value={product.inventory_qty}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="status" className="mb-3">
          <Form.Check
            type="checkbox"
            name="status"
            label="Available"
            checked={product.status}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? "Saving..." : "Save Changes"}
        </Button>
      </Form>
    </div>
  );
};

export default ProductEdit;
