import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap"; // Import necessary components
import Swal from "sweetalert2"; // For alert dialogs
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { FaEye, FaEyeSlash } from "react-icons/fa";

const CreateUsers = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    outlet: "", // Store outlet ID
    is_salesperson: false,
    password: "",
    is_admin: false,
    status: "active", // Default status
  });
  const [outlets, setOutlets] = useState([]); // State for outlets
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchOutlets = async () => {
      const token = localStorage.getItem("token"); // Retrieve access token

      try {
        const response = await fetch("https://bwsoft.in/api/outletlist/", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Include token in the request
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        // Check if data is an array
        if (Array.isArray(data)) {
          setOutlets(data);
        } else {
          console.error("Expected an array but received:", data);
        }
      } catch (error) {
        console.error("Error fetching outlets:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchOutlets(); // Call the function to fetch outlets
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUser({
      ...user,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token"); // Retrieve access token

    try {
      const response = await fetch("https://bwsoft.in/api/user/add/", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        Swal.fire("Success!", "User created successfully.", "success");
        // Clear the form
        setUser({
          username: "",
          first_name: "",
          last_name: "",
          email: "",
          mobile: "",
          outlet: "", // Reset outlet
          is_salesperson: false,
          password: "",
          is_admin: false,
          status: "active", // Reset status to default
        });
      } else {
        throw new Error("Failed to create user");
      }
    } catch (error) {
      console.error("Error creating user:", error);
      Swal.fire("Error!", "Failed to create the user.", "error");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  if (loading) {
    return <div className="text-center">Loading outlets...</div>; // Loading message for outlets
  }

  return (
    <div className="container mt-4" id="tabelcontent">
      <h1>Create User</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formUsername">
          <Form.Label>Username *</Form.Label>
          <Form.Control
            type="text"
            name="username"
            value={user.username}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formFirstName">
          <Form.Label>First Name *</Form.Label>
          <Form.Control
            type="text"
            name="first_name"
            value={user.first_name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formLastName">
          <Form.Label>Last Name *</Form.Label>
          <Form.Control
            type="text"
            name="last_name"
            value={user.last_name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email Address *</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={user.email}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formMobile">
          <Form.Label>Mobile *</Form.Label>
          <Form.Control
            type="text"
            name="mobile"
            value={user.mobile}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formOutlet">
          <Form.Label>Select Outlet *</Form.Label>
          <Form.Select
            name="outlet"
            value={user.outlet}
            onChange={handleChange}
            required
          >
            <option value="">Select an outlet</option>
            {outlets.map((outlet) => (
              <option key={outlet.id} value={outlet.id}>
                {outlet.name} {/* Assuming outlet has a name property */}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="formPassword">
          <Form.Label>Set Password *</Form.Label>
          <div className="input-group">
            <Form.Control
              type={showPassword ? "text" : "password"} // Change type based on state
              name="password"
              value={user.password}
              onChange={handleChange}
              required
            />
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Toggle icon */}
            </button>
          </div>
        </Form.Group>

        <Form.Group controlId="formIsAdmin" className="mb-3">
          <Form.Label>Is Admin?</Form.Label>
          <Form.Check
            type="checkbox"
            name="is_admin"
            label="Admin"
            checked={user.is_admin}
            onChange={handleChange}
            className="ms-2" // Add some left margin
          />
        </Form.Group>

        <Form.Group controlId="formIsSalesperson" className="mb-3">
          <Form.Label>Is Salesperson?</Form.Label>
          <Form.Check
            type="checkbox"
            name="is_salesperson"
            label="Salesperson"
            checked={user.is_salesperson}
            onChange={handleChange}
            className="ms-2" // Add some left margin
          />
        </Form.Group>

        <Form.Group controlId="formStatus">
          <Form.Label>Status *</Form.Label>
          <Form.Select
            name="status"
            value={user.status}
            onChange={handleChange}
            required
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </Form.Select>
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-5">
          Create User
        </Button>
      </Form>
    </div>
  );
};

export default CreateUsers;
