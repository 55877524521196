import React, { useEffect, useState } from "react";
import { Table, Spinner, Form, Button } from "react-bootstrap"; // Import necessary components
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Link } from "react-router-dom"; // For linking to edit page

const PaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState([]); // State for payment methods data
  const [loading, setLoading] = useState(true); // Loading state
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [itemsPerPage, setItemsPerPage] = useState(10); // State for items per page
  const [currentPage, setCurrentPage] = useState(1); // State for current page

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const token = localStorage.getItem("token"); // Retrieve access token

      try {
        const response = await fetch("https://bwsoft.in/api/paymentlist/", {
          method: "GET", // Use GET method for this API
          headers: {
            Authorization: `Bearer ${token}`, // Include token in the request
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) throw new Error("Failed to fetch payment methods");

        const data = await response.json();
        setPaymentMethods(data); // Set the payment methods data
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchPaymentMethods(); // Call the function to fetch payment methods
  }, []);

  const filteredMethods = paymentMethods.filter((method) =>
    method.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedMethods = filteredMethods.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredMethods.length / itemsPerPage);

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" /> Loading payment methods...
      </div>
    ); // Loading spinner
  }

  return (
    <div className="container mt-4" id="tabelcontent">
      <div className="mb-3 d-flex flex-row flex-md-row gap-2 justify-content-between">
        <h1>Payment Methods</h1>
        <Link to="/Createpaymethods">
          <Button variant="primary" className="mb-2 mb-md-0 me-md-2">
            Add Payment Methods
          </Button>
        </Link>
      </div>

      <Form.Control
        type="text"
        placeholder="Search by name..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-3"
      />

      <Table striped bordered hover>
        <thead className="table-dark">
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {paginatedMethods.length > 0 ? (
            paginatedMethods.map((method) => (
              <tr key={method.id}>
                <td>{method.name}</td>
                <td>{method.status ? "Active" : "Inactive"}</td>
                <td>
                  <Link to={`/Editpaymethod/${method.id}`}>
                    <button className="btn btn-warning btn-sm">Edit</button>
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="text-center">
                No payment methods found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          Items per page:
          <Form.Select
            className="mx-2"
            defaultValue={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1); // Reset to first page when items per page changes
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </Form.Select>
        </div>
        <div>
          {filteredMethods.length > 0
            ? `1 – ${Math.min(
                currentPage * itemsPerPage,
                filteredMethods.length
              )} of ${filteredMethods.length}`
            : "No items found"}
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          className="btn btn-secondary"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </button>
        <button
          className="btn btn-secondary"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PaymentMethods;
