import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Swal from "sweetalert2";
import axios from "axios";

const Authform = ({ setIsAuthenticated }) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate(); // Initialize navigate hook

  // Function to get the CSRF token from cookies
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrftoken = getCookie("csrftoken");

  const handleSignInClick = async () => {
    setError("");
    setLoading(true); // Set loading to true

    try {
      const response = await axios.post(
        "https://bwsoft.in/api/admin/login/",
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        }
      );
      const token = response.data.accessToken;
      const tokenExpirationTime = Date.now() + 10 * 60 * 1000;
      localStorage.setItem("token", token);
      localStorage.setItem("tokenExpirationTime", tokenExpirationTime);
      localStorage.setItem("isAuthenticated", "true");
      setIsAuthenticated(true);

      console.log("Login successful:", response.data);
      setEmail("");
      setPassword("");
      localStorage.setItem("token", response.data.accessToken); // Save the access token
      localStorage.setItem("isAuthenticated", "true");
      setIsAuthenticated(true); // Update state in App.js
      console.log("Stored Token:", localStorage.getItem("token"));

      // Show SweetAlert for successful login
      Swal.fire({
        title: "Success!",
        text: "You have logged in successfully.",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/");
      });
    } catch (error) {
      console.error("Error during login:", error);
      setError(
        error.response?.data?.detail ||
          "Login failed. Please check your credentials."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="AuthForm-main">
      <div
        className={`AuthForm-main-AuthForm-container ${
          isSignUp ? "AuthForm-main-right-panel-active" : ""
        }`}
      >
        <div className="AuthForm-main-form-container AuthForm-main-sign-in-container">
          <form>
            <h1>LOG IN</h1>

            <div className="AuthForm-main-infield">
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="AuthForm-main-infield">
              <input
                type={showPassword ? "text" : "password"} // Toggle password visibility
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />

              <span
                onClick={handleTogglePassword}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {error && <p className="error-message">{error}</p>}

            <button
              type="button"
              onClick={handleSignInClick}
              disabled={loading}
            >
              {loading ? "Logging in..." : "LOG IN"}
            </button>
          </form>
        </div>

        <div className="AuthForm-main-overlay-container">
          <div className="AuthForm-main-overlay">
            <div className="AuthForm-main-overlay-panel AuthForm-main-overlay-right">
              <h1>Hello, Friend!</h1>
              <p>Welcome to our Billing Software</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authform;
