import React, { useState } from "react";
import { Form, Button } from "react-bootstrap"; // Import necessary components
import Swal from "sweetalert2"; // For alert dialogs
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Createsuppliers = () => {
  const [supplier, setSupplier] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
    tax: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSupplier({
      ...supplier,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token"); // Retrieve access token

    try {
      const response = await fetch("https://bwsoft.in/api/supplier/create/", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Include token in the request
          "Content-Type": "application/json",
        },
        body: JSON.stringify(supplier),
      });

      const data = await response.json();

      if (response.ok) {
        Swal.fire("Success!", data.message, "success");
        // Clear form after successful submission
        setSupplier({
          name: "",
          email: "",
          mobile: "",
          address: "",
          tax: "",
        });
      } else {
        throw new Error(data.message || "Failed to create supplier");
      }
    } catch (error) {
      console.error("Error creating supplier:", error);
      Swal.fire("Error!", error.message, "error");
    }
  };

  return (
    <div className="container mt-4" id="tabelcontent">
      <h1>Create Supplier</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formSupplierName">
          <Form.Label>Supplier Name *</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={supplier.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmailAddress">
          <Form.Label>Email Address *</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={supplier.email}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formContact">
          <Form.Label>Contact *</Form.Label>
          <Form.Control
            type="text"
            name="mobile"
            value={supplier.mobile}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formAddress">
          <Form.Label>Address *</Form.Label>
          <Form.Control
            type="text"
            name="address"
            value={supplier.address}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formTax">
          <Form.Label>Tax *</Form.Label>
          <Form.Control
            type="number"
            name="tax"
            value={supplier.tax}
            onChange={handleChange}
            required
            step="0.01" // Allow decimal values
          />
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3">
          Create Supplier
        </Button>
      </Form>
    </div>
  );
};

export default Createsuppliers;
