import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Table,
  Spinner,
  Dropdown,
  Col,
  Row,
  DropdownButton,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import * as XLSX from "xlsx"; // For Excel export

const ReturnReport = () => {
  const [outlets, setOutlets] = useState([]);
  const [paidByOptions, setPaidByOptions] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [selectedPaidBy, setSelectedPaidBy] = useState("");
  const [refundStatus, setRefundStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [visibleColumns, setVisibleColumns] = useState({
    dateTime: true,
    returnId: true,
    outlet: true,
    customer: true,
    refundMethod: true,
    refundStatus: true,
    refundAmount: true,
    salesId: true,
    remarks: true,
  });
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchOutletsAndPaidByOptions = async () => {
      const token = localStorage.getItem("token");
      try {
        const [outletsResponse, paidByResponse] = await Promise.all([
          axios.get("https://bwsoft.in/api/outletlist/", {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get("https://bwsoft.in/api/paymentlist/", {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);
        setOutlets(outletsResponse.data);
        setPaidByOptions(paidByResponse.data);
      } catch (error) {
        console.error("Error fetching outlets or payment methods:", error);
        Swal.fire(
          "Error!",
          "Failed to fetch outlets or payment methods.",
          "error"
        );
      }
    };

    fetchOutletsAndPaidByOptions();
  }, []);

  const handleSearch = async () => {
    setLoading(true);
    setError("");

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://bwsoft.in/api/getReturnOrders/",
        {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            outlet: selectedOutlet,
            paid_by: selectedPaidBy,
            refund_status: refundStatus === "all" ? undefined : refundStatus,
            start_date: startDate,
            end_date: endDate,
          },
        }
      );
      setSalesData(response.data);
    } catch (error) {
      console.error("Error fetching sales data:", error);
      setError("Failed to fetch sales data.");
    } finally {
      setLoading(false);
    }
  };

  const toggleColumn = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const handleExportToExcel = () => {
    const filteredData = salesData.map((sale) => ({
      DateTime: new Date(sale.created_at).toLocaleString(),
      ReturnID: sale.id,
      Outlet: outlets.find((o) => o.id === sale.outlet_id)?.name || "N/A",
      Customer: sale.customer_id, // You may want to fetch customer name as well
      RefundMethod:
        paidByOptions.find((p) => p.id === sale.refund_by)?.name || "N/A",
      RefundStatus: sale.refund_status === 1 ? "Processed" : "Pending",
      RefundAmount: parseFloat(sale.refund_amount).toFixed(2),
      SalesId: sale.order_id,
      Remarks: sale.remark,
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ReturnData");

    // Export to Excel
    XLSX.writeFile(workbook, "Return_Report.xlsx");
  };

  const filteredSalesData = salesData.filter((sale) =>
    searchTerm ? sale.customer_id.toString().includes(searchTerm) : true
  );

  return (
    <div className="container mt-4" id="tabelcontent">
      <h2>Return Report</h2>

      <Form>
        <Row className="mb-3">
          <Col md={4} xs={12}>
            <Form.Group controlId="formOutlet">
              <Form.Label>Choose Outlet *</Form.Label>
              <Form.Control
                as="select"
                value={selectedOutlet}
                onChange={(e) => setSelectedOutlet(e.target.value)}
                required
              >
                <option value="">-- Select Outlet --</option>
                <option value="all">All</option>
                {outlets.map((outlet) => (
                  <option key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={4} xs={12}>
            <Form.Group controlId="formPaidBy">
              <Form.Label>Choose Paid By *</Form.Label>
              <Form.Control
                as="select"
                value={selectedPaidBy}
                onChange={(e) => setSelectedPaidBy(e.target.value)}
                required
              >
                <option value="">-- Refund Method --</option>
                <option value="all">All</option>
                {paidByOptions.map((method) => (
                  <option key={method.id} value={method.id}>
                    {method.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={4} xs={12}>
            <Form.Group controlId="formRefundStatus">
              <Form.Label>Choose Refund Status *</Form.Label>
              <Form.Control
                as="select"
                value={refundStatus}
                onChange={(e) => setRefundStatus(e.target.value)}
                required
              >
                <option value="">-- Select Refund Status --</option>
                <option value="all">All</option>
                <option value="1">Processed</option>
                <option value="0">Pending</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Form>
        <Row className="mb-3">
          <Col md={4} xs={12}>
            <Form.Group controlId="formStartDate">
              <Form.Label>Start Date *</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={4} xs={12}>
            <Form.Group controlId="formEndDate">
              <Form.Label>End Date *</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col
            md={4}
            xs={12}
            className="d-flex justify-content-center  align-items-center"
          >
            <div className="d-flex justify-content-center excelbtn  align-items-flexend mt-3">
              <Button variant="primary" onClick={handleSearch}>
                Get Report
              </Button>
            </div>
          </Col>
        </Row>
      </Form>

      <div className="mb-3">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
          <Form.Control
            type="search"
            placeholder="Search by Customer ID..."
            className="mb-2 mb-md-0 me-md-2 flex-fill"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <div className="d-flex align-items-center mb-2 mb-md-0">
            <DropdownButton
              id="dropdown-basic-button"
              title="Column Filters"
              variant="success"
              className="me-2"
            >
              {Object.keys(visibleColumns).map((column) => (
                <Form.Check
                  key={column}
                  type="checkbox"
                  label={column.replace(/([A-Z])/g, " $1").trim()} // Format column name
                  checked={visibleColumns[column]}
                  onChange={() => toggleColumn(column)}
                />
              ))}
            </DropdownButton>

            <Button
              variant="success"
              className="excelbtn"
              onClick={handleExportToExcel}
            >
              Export to Excel
            </Button>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" />
          <p>Loading...</p>
        </div>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <Table striped bordered hover responsive className="mt-4">
            <thead>
              <tr>
                {visibleColumns.dateTime && <th>Date & Time</th>}
                {visibleColumns.returnId && <th>Return ID</th>}
                {visibleColumns.outlet && <th>Outlet</th>}
                {visibleColumns.customer && <th>Customer</th>}
                {visibleColumns.refundMethod && <th>Refund Method</th>}
                {visibleColumns.refundStatus && <th>Refund Status</th>}
                {visibleColumns.refundAmount && <th>Refund Amount (INR)</th>}
                {visibleColumns.salesId && <th>Sales ID</th>}
                {visibleColumns.remarks && <th>Remarks</th>}
              </tr>
            </thead>
            <tbody>
              {filteredSalesData.length > 0 ? (
                filteredSalesData.map((sale) => (
                  <tr key={sale.id}>
                    {visibleColumns.dateTime && (
                      <td>{new Date(sale.created_at).toLocaleString()}</td>
                    )}
                    {visibleColumns.returnId && <td>{sale.id}</td>}
                    {visibleColumns.outlet && (
                      <td>
                        {outlets.find((o) => o.id === sale.outlet_id)?.name ||
                          "N/A"}
                      </td>
                    )}
                    {visibleColumns.customer && <td>{sale.customer_id}</td>}
                    {visibleColumns.refundMethod && (
                      <td>
                        {paidByOptions.find((p) => p.id === sale.refund_by)
                          ?.name || "N/A"}
                      </td>
                    )}
                    {visibleColumns.refundStatus && (
                      <td>
                        {sale.refund_status === 1 ? "Processed" : "Pending"}
                      </td>
                    )}
                    {visibleColumns.refundAmount && (
                      <td>{parseFloat(sale.refund_amount).toFixed(2)}</td>
                    )}
                    {visibleColumns.salesId && <td>{sale.order_id}</td>}
                    {visibleColumns.remarks && <td>{sale.remark}</td>}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center">
                    No Records Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

export default ReturnReport;
