import React, { useState, useEffect } from "react";
import { Table, Button, FormControl } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ManageCategory = () => {
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      const token = localStorage.getItem("token"); // Retrieve token from local storage
      try {
        const response = await axios.get(
          "https://bwsoft.in/api/categorylist/",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include token in the headers
            },
          }
        );
        setCategories(response.data);
      } catch (err) {
        setError("Failed to fetch categories");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      const token = localStorage.getItem("token"); // Retrieve token from local storage
      try {
        await axios.delete(`https://bwsoft.in/api/category/delete/${id}/`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in the headers
          },
        });
        setCategories(categories.filter((category) => category.id !== id));
        alert("Category deleted successfully");
      } catch (err) {
        setError("Failed to delete category");
        console.error(err);
      }
    }
  };

  return (
    <div className="container mt-4" id="tabelcontent">
      <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
        <h2>Manage Categories</h2>
        <div className="mb-3 d-flex flex-row flex-md-row gap-2">
          <Button variant="primary" className="mb-2 mb-md-0 me-md-2">
            Add Category
          </Button>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <FormControl
          type="search"
          placeholder="Search Category..."
          className="me-2"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

      {loading && <div>Loading categories...</div>}
      {error && <div className="text-danger">{error}</div>}

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Created</th>
            <th>Updated</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredCategories.length > 0 ? (
            filteredCategories.map((category) => (
              <tr key={category.id}>
                <td>{category.name}</td>
                <td>{category.status ? "Active" : "Inactive"}</td>
                <td>{new Date(category.created_at).toLocaleDateString()}</td>
                <td>{new Date(category.updated_at).toLocaleDateString()}</td>
                <td>
                  <Button
                    variant="warning"
                    size="sm"
                    className="me-2"
                    onClick={() => navigate(`/EditCategory/${category.id}`)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(category.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No categories found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ManageCategory;
