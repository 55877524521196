import React, { useEffect, useState } from "react";
import { Table, Button, Form, FormControl } from "react-bootstrap"; // Importing necessary components
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Swal from "sweetalert2"; // For alert dialogs
import { Link } from "react-router-dom";

const Outlets = () => {
  const [outlets, setOutlets] = useState([]); // Initialize outlets as an array
  const [loading, setLoading] = useState(true); // Loading state
  const [searchTerm, setSearchTerm] = useState(""); // State for search input

  useEffect(() => {
    const fetchOutlets = async () => {
      const token = localStorage.getItem("token"); // Retrieve access token

      try {
        const response = await fetch("https://bwsoft.in/api/outletlist/", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Include token in the request
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        // Check if data is an array
        if (Array.isArray(data)) {
          setOutlets(data);
        } else {
          console.error("Expected an array but received:", data);
        }
      } catch (error) {
        console.error("Error fetching outlets:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchOutlets();
  }, []);

  const handleEdit = async (outlet) => {
    const token = localStorage.getItem("token"); // Retrieve access token
    const updatedOutlet = {
      name: "Updated Outlet Name", // Replace with actual data from a form or input
      address: "Updated Address",
      mobile: "1234567890",
      receipt_footer: "Thank you for shopping with us.",
      status: true,
    };

    try {
      const response = await fetch(
        `https://bwsoft.in/api/outlet/update/${outlet.id}/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedOutlet),
        }
      );

      if (response.ok) {
        Swal.fire("Success!", "Outlet updated successfully.", "success");
        // Optionally refetch outlets or update the state
      } else {
        throw new Error("Failed to update outlet");
      }
    } catch (error) {
      console.error("Error updating outlet:", error);
      Swal.fire("Error!", "Failed to update the outlet.", "error");
    }
  };

  const handleDelete = async (outletId) => {
    const token = localStorage.getItem("token"); // Retrieve access token

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this outlet?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `https://bwsoft.in/api/outlet/delete/${outletId}/`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          setOutlets(outlets.filter((outlet) => outlet.id !== outletId));
          Swal.fire("Deleted!", "The outlet has been deleted.", "success");
        } else {
          throw new Error("Failed to delete outlet");
        }
      } catch (error) {
        console.error("Error deleting outlet:", error);
        Swal.fire("Error!", "Failed to delete the outlet.", "error");
      }
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>; // Show loading message while fetching
  }

  // Handle search functionality
  const filteredOutlets = outlets.filter((outlet) =>
    outlet.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mt-4" id="tabelcontent">
      <div className="mb-3 d-flex flex-row flex-md-row gap-2 justify-content-between">
        <h1>Outlets</h1>
        <Link to="/Createoutlet">
          <Button variant="primary" className="mb-2 mb-md-0 me-md-2">
            Add Outlets
          </Button>
        </Link>
      </div>

      <Form className="mb-3">
        <FormControl
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Form>
      <Table striped bordered hover>
        <thead className="table-dark">
          <tr>
            <th>Name</th>
            <th>Address</th>
            <th>Contact</th>
            <th>Status</th>
            <th>Created</th>
            <th>Updated</th>
            <th>Edit</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {filteredOutlets.length > 0 ? (
            filteredOutlets.map((outlet) => (
              <tr key={outlet.id}>
                <td>{outlet.name}</td>
                <td>{outlet.address.replace(/\n/g, ", ")}</td>
                <td>{outlet.mobile}</td>
                <td>{outlet.status ? "Active" : "Inactive"}</td>
                <td>
                  {outlet.created_by.username} -{" "}
                  {new Date(outlet.created_at).toLocaleDateString()}
                </td>
                <td>
                  {outlet.updated_by
                    ? `${outlet.updated_by.username} - ${new Date(
                        outlet.updated_at
                      ).toLocaleDateString()}`
                    : "N/A"}
                </td>
                <td>
                  <Link to={`/EditOutlet/${outlet.id}`}>
                    <Button variant="warning" size="sm" className="me-2">
                      Edit
                    </Button>
                  </Link>
                </td>
                <td>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(outlet.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                No outlets found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          Items per page:
          <Form.Select className="mx-2" defaultValue="10">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </Form.Select>
        </div>
        <div>
          1 – {filteredOutlets.length} of {outlets.length}
        </div>
      </div>
    </div>
  );
};

export default Outlets;
