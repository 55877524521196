import { Table, Button, Modal, ListGroup, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Bill = () => {
  const [invoiceDetails, setInvoiceDetails] = useState({
    items: [],
    sgstRate: 9,
    cgstRate: 9,
    igstRate: 0,
    discountRate: 0,
  });

  const [productList, setProductList] = useState([]);
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [outletList, setOutletList] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [paidAmount, setPaidAmount] = useState(0);
  const [returnChange, setReturnChange] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [settinglist, setSettinglist] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    // Fetch product list
    axios
      .get("https://bwsoft.in/api/productlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setProductList(response.data);
          setFilteredProductList(response.data);
        }
      })
      .catch((error) => console.error("Error fetching products:", error));

    // Fetch outlet list
    axios
      .get("https://bwsoft.in/api/outletlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setOutletList(response.data);
        }
      })
      .catch((error) => console.error("Error fetching outlets:", error));

    axios
      .get("https://bwsoft.in/api/paymentlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setPaymentMethods(response.data.filter((method) => method.status));
          setPaymentMethod(response.data[0]?.name || "Cash"); // Set default payment method
        }
      })
      .catch((error) =>
        console.error("Error fetching payment methods:", error)
      );

    axios
      .get("https://bwsoft.in/api/customerlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setCustomerList(response.data);
        }
      })
      .catch((error) => console.error("Error fetching customers:", error));
    // Open the modal when the component mounts
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    const fetchSettingList = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch("https://bwsoft.in/api/settinglist/", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) throw new Error("Failed to fetch settings");

        const data = await response.json();
        console.log(data); // Log to check the structure
        setSettinglist(Array.isArray(data) ? data : []); // Ensure it's an array
      } catch (error) {
        console.error("Error fetching setting list:", error);
      }
    };

    fetchSettingList();
  }, []);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);
    const filteredProducts = productList.filter(
      (product) =>
        product.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        product.code.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredProductList(filteredProducts);
  };

  const handleSelectProduct = (product) => {
    const newItem = {
      id: product.id,
      name: product.name,
      code: product.code || "N/A",
      price: parseFloat(product.retail_price) || 0,
      quantity: 1,
    };

    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      items: [...prevDetails.items, newItem],
    }));

    setSearchTerm("");
    setFilteredProductList(productList); // Reset filtered product list
  };

  const handleQuantityChange = (itemId, amount) => {
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      items: prevDetails.items.map((item) =>
        item.id === itemId
          ? { ...item, quantity: Math.max(1, item.quantity + amount) }
          : item
      ),
    }));
  };

  const subtotal = invoiceDetails.items.reduce(
    (acc, item) => acc + (item.price * item.quantity || 0),
    0
  );

  const sgstAmount = (subtotal * invoiceDetails.sgstRate) / 100;
  const cgstAmount = (subtotal * invoiceDetails.cgstRate) / 100;
  const igstAmount = (subtotal * invoiceDetails.igstRate) / 100;
  const totalGst = sgstAmount + cgstAmount + igstAmount;
  const discountAmount = (subtotal * invoiceDetails.discountRate) / 100;
  const totalPayable = subtotal + totalGst - discountAmount;

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSelectOutlet = (outlet) => {
    setSelectedOutlet(outlet); // Set the entire outlet object
    localStorage.setItem("selectedOutletId", outlet.id); // Optionally store in localStorage
    handleCloseModal();
  };

  const handleOpenPaymentModal = () => {
    setIsPaymentModalOpen(true);
  };

  const navigate = useNavigate(); // Use useNavigate inside the component

  // Handle make payment function
  const handleMakePayment = (
    selectedOutlet,
    selectedCustomer,
    paymentMethod,
    totalPayable,
    paidAmount,
    invoiceDetails,
    selectedAccount
  ) => {
    console.log("Payment Details Received:");
    console.log("Selected Outlet:", selectedOutlet);
    console.log("Selected Customer:", selectedCustomer);
    console.log("Payment Method:", paymentMethod);
    console.log("Total Payable:", totalPayable);
    console.log("Paid Amount:", paidAmount);
    console.log("Invoice Details:", invoiceDetails);
    console.log("Selected Account:", selectedAccount);
    // Ensure you have the correct selected values
    if (!selectedOutlet || !selectedCustomer || !paymentMethod) {
      alert("Please select an outlet, customer, and payment method.");
      return;
    }

    const orderData = {
      card_number: "", // Leave empty if not applicable
      cgst_charge: "0.00", // Use string as required
      cgst_percent: "0.00", // Use string as required
      cheque_number: "", // Leave empty if not applicable
      created_by: 1, // Adjust as needed
      customer_id: String(selectedCustomer), // Ensure this is a string
      discount_percentage: "0.00", // Use string as required
      discount_total: "0.00", // Use string as required
      grand_total: totalPayable.toFixed(2), // Ensure this is a string
      igst_charge: "0.00", // Use string as required
      igst_percent: "0.00", // Use string as required
      order_items: invoiceDetails.items.map((item) => ({
        product_id: item.id, // Ensure this is correct
        product_name: item.name,
        product_code: item.code,
        qty: item.quantity, // Ensure this is a number
        category_id: item.categoryId || 0, // Ensure this is a number
        purchase_price: item.purchasePrice || "0.00", // Ensure this is included and is a string
        retail_price: item.retailPrice || "0.00", // Include retail_price and ensure it's a string
      })),
      outlet_id: selectedOutlet.id, // Ensure this is set and is a number
      paid_amount: totalPayable.toFixed(2), // Ensure this is a string
      payment_id: parseInt(paymentMethod, 10), // Ensure this is a number
      refund_status: 0, // Assuming this is a fixed value
      remark: "", // Leave empty if not applicable
      return_change: (paidAmount - totalPayable).toFixed(2), // Calculate return change
      sgst_charge: "0.00", // Use string as required
      sgst_percent: "0.00", // Use string as required
      status: 1, // Assuming this is a fixed value
      subtotal: totalPayable.toFixed(2), // Ensure this is a string
      total_items: invoiceDetails.items.length, // This should match the length of order_items
      updated_by: null, // Can leave empty or set to null
      vt_status: 1, // Assuming this is a fixed value
      selected_account: selectedAccount, // Pass the selected account here
    };

    // Get the token from localStorage
    const token = localStorage.getItem("token");

    // Set up the request config with headers
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .post("https://bwsoft.in/api/create_order/", orderData, config)
      .then((response) => {
        console.log("Order created successfully:", response.data);
        // Use navigate to go to the Createbill page with order details
        navigate("/Createbill", {
          state: { orderDetails: response.data, selectedAccount },
        });
      })
      .catch((error) => {
        console.error("Error creating order:", error.response.data);
      });
  };

  return (
    <div className="bill-template" id="tabelcontent">
      <h1 className="text-center">Invoice</h1>
      <div className="bill-header">
        <div className="product-search">
          <div
            style={{ position: "relative", width: "100%", maxWidth: "400px" }}
          >
            <input
              type="text"
              id="product-search"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Type product name or code..."
              style={{
                width: "100%",
                padding: "10px 40px 10px 15px", // Padding for space around the icon
                border: "2px solid #ddd",
                borderRadius: "8px",
                fontSize: "16px",
              }}
            />
            <span
              style={{
                position: "absolute",
                right: "15px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                color: "#999",
              }}
            >
              🔍{" "}
              {/* Search Icon - you can replace this with a search icon from a library */}
            </span>
          </div>

          {searchTerm && filteredProductList.length > 0 && (
            <ul className="product-dropdown">
              {filteredProductList.map((product) => (
                <li
                  key={product.id}
                  onClick={() => handleSelectProduct(product)}
                  className="dropdown-item"
                >
                  {product.name} - ₹{product.retail_price || 0}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div>
          {" "}
          <p>
            Outlet:{" "}
            <span style={{ fontSize: "25px" }}>
              {selectedOutlet ? selectedOutlet.name : "No outlet selected"}
            </span>
          </p>
        </div>
        <div>
          {" "}
          <Button onClick={handleOpenModal}>Change Outlet</Button>
        </div>
      </div>
      <Table striped bordered hover className="bill-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Product Name</th>
            <th>HSN Code</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {invoiceDetails.items.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.code}</td>
              <td>
                <Button onClick={() => handleQuantityChange(item.id, -1)}>
                  -
                </Button>
                {item.quantity}
                <Button onClick={() => handleQuantityChange(item.id, 1)}>
                  +
                </Button>
              </td>
              <td>₹{item.price.toFixed(2)}</td>
              <td>₹{(item.price * item.quantity).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="bill-summary">
        <p>Subtotal: ₹{subtotal.toFixed(2)}</p>
        <p>
          Total Purchase Quantity:{" "}
          {invoiceDetails.items.reduce((acc, item) => acc + item.quantity, 0)}
        </p>
        <p>
          SGST ({invoiceDetails.sgstRate}%): ₹{sgstAmount.toFixed(2)}
        </p>
        <p>
          CGST ({invoiceDetails.cgstRate}%): ₹{cgstAmount.toFixed(2)}
        </p>
        <p>
          IGST ({invoiceDetails.igstRate}%): ₹{igstAmount.toFixed(2)}
        </p>
        <p>
          Discount ({invoiceDetails.discountRate}%): -₹
          {discountAmount.toFixed(2)}
        </p>
        <p>
          <strong>Total Payable: ₹{totalPayable.toFixed(2)}</strong>
        </p>
      </div>

      <div className="bill-footer d-flex g-2 justify-content-evenly">
        <Button variant="danger">Cancel</Button>
        <Button variant="danger"> Hold Bill</Button>
        <Button variant="primary" onClick={handleOpenPaymentModal}>
          Make Payment
        </Button>
      </div>
      <p>Thank you for your business!</p>

      <Modal
        show={isPaymentModalOpen}
        onHide={() => setIsPaymentModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Make Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="customerName">
            <Form.Label>Select Customer</Form.Label>
            <Form.Control
              as="select"
              value={selectedCustomer}
              onChange={(e) => setSelectedCustomer(e.target.value)}
            >
              <option value="">Select Customer</option>
              {customerList.map((customer) => (
                <option key={customer.id} value={customer.id}>
                  {customer.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="accountSelect">
            <Form.Label>Select Account</Form.Label>
            <Form.Control
              as="select"
              value={selectedAccount}
              onChange={(e) => setSelectedAccount(e.target.value)}
            >
              <option value="">Select account</option>
              {Array.isArray(settinglist) && settinglist.length > 0 ? (
                settinglist.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.name} {/* Ensure you show the account name */}
                  </option>
                ))
              ) : (
                <option disabled>No accounts available</option>
              )}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="paymentMethod">
            <Form.Label>Select Payment Method</Form.Label>
            <Form.Control
              as="select"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(Number(e.target.value))} // Convert to number
            >
              <option value="">Select Payment Method</option>
              {paymentMethods.map((method) => (
                <option key={method.id} value={method.id}>
                  {method.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="paidAmount">
            <Form.Label>Paid Amount</Form.Label>
            <Form.Control
              type="number"
              value={paidAmount}
              onChange={(e) => setPaidAmount(Number(e.target.value))}
            />
          </Form.Group>

          <Form.Group controlId="returnChange">
            <Form.Label>Return Change</Form.Label>
            <Form.Control
              type="text"
              value={`₹${(paidAmount - totalPayable).toFixed(2)}`}
              readOnly
            />
          </Form.Group>

          <p>Total Payable Amount: ₹{totalPayable.toFixed(2)}</p>
          <p>Total Purchased Items: {invoiceDetails.items.length}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() =>
              handleMakePayment(
                selectedOutlet,
                selectedCustomer,
                paymentMethod,
                totalPayable,
                paidAmount,
                invoiceDetails,
                selectedAccount
              )
            }
          >
            Make Payment
          </Button>
          <Button
            variant="secondary"
            onClick={() => setIsPaymentModalOpen(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isModalOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Outlet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {outletList.map((outlet) => (
              <ListGroup.Item
                key={outlet.id}
                action
                onClick={() => handleSelectOutlet(outlet)}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <strong>{outlet.name}</strong>
                <div>{outlet.address}</div>
                <div>Mobile:{outlet.mobile}</div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <style jsx>{`
        .product-dropdown {
          list-style: none;
          padding: 0;
          margin: 0;
          border: 1px solid #ccc;
          max-height: 100px;
          overflow-y: auto;
          position: absolute;
          background-color: white;
          z-index: 10;
          width: calc(30% - 20px);
        }

        .dropdown-item {
          padding: 8px 12px;
          cursor: pointer;
        }

        .dropdown-item:hover {
          background-color: #f1f1f1;
        }

        .bill-table {
          margin-top: 20px;
        }
      `}</style>
    </div>
  );
};

export default Bill;
