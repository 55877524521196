import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap"; // Import necessary components
import { useParams, useNavigate } from "react-router-dom"; // For route parameters and navigation
import Swal from "sweetalert2"; // For alert dialogs
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const EditOutlet = () => {
  const { id } = useParams(); // Get outlet ID from URL parameters
  const navigate = useNavigate(); // For navigation after editing
  const [outlet, setOutlet] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOutlet = async () => {
      const token = localStorage.getItem("token"); // Retrieve access token
      try {
        const response = await fetch(
          `https://bwsoft.in/api/outlet/update/${id}/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          setOutlet(data);
        } else {
          console.error("Failed to fetch outlet:", data);
          Swal.fire("Error!", "Failed to load outlet data.", "error");
        }
      } catch (error) {
        console.error("Error fetching outlet:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOutlet();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOutlet({
      ...outlet,
      [name]: name === "status" ? value === "true" : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token"); // Retrieve access token

    try {
      const response = await fetch(
        `https://bwsoft.in/api/outlet/update/${id}/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(outlet),
        }
      );

      if (response.ok) {
        Swal.fire("Success!", "Outlet updated successfully.", "success");
        navigate("/outlets"); // Redirect after successful update
      } else {
        throw new Error("Failed to update outlet");
      }
    } catch (error) {
      console.error("Error updating outlet:", error);
      Swal.fire("Error!", "Failed to update the outlet.", "error");
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div className="container mt-4" id="tabelcontent">
      <h1>Edit Outlet</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formOutletName">
          <Form.Label>Outlet Name *</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={outlet.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formOutletAddress">
          <Form.Label>Address *</Form.Label>
          <Form.Control
            type="text"
            name="address"
            value={outlet.address}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formOutletContact">
          <Form.Label>Contact *</Form.Label>
          <Form.Control
            type="text"
            name="mobile"
            value={outlet.mobile}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formOutletStatus">
          <Form.Label>Status *</Form.Label>
          <Form.Select
            name="status"
            value={outlet.status.toString()}
            onChange={handleChange}
            required
          >
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="formReceiptFooter">
          <Form.Label>Receipt Footer</Form.Label>
          <Form.Control
            as="textarea"
            name="receipt_footer"
            value={outlet.receipt_footer}
            onChange={handleChange}
            rows={5}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Update Outlet
        </Button>
      </Form>
    </div>
  );
};

export default EditOutlet;
