import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams, useLocation } from "react-router-dom";

const UpdateInventory = () => {
  const { id } = useParams(); // Get the inventory ID from the URL
  const location = useLocation(); // Get location for query parameters
  const [productId, setProductId] = useState("");
  const [outletId, setOutletId] = useState("");
  const [quantity, setQuantity] = useState("");
  const [productName, setProductName] = useState("");
  const [outletName, setOutletName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const productID = params.get("productId"); // Get product ID from query params
    const outletID = params.get("outletId"); // Get outlet ID from query params

    // Fetch inventory details
    const fetchInventoryDetails = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `https://bwsoft.in/api/inventory/update/${id}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setQuantity(response.data.qty);
        setProductId(productID); // Set product ID
        setOutletId(outletID); // Set outlet ID

        // Fetch product name
        const productResponse = await axios.get(
          `https://bwsoft.in/api/getproduct/${productID}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProductName(productResponse.data.name);

        // Fetch outlet name
        const outletResponse = await axios.get(
          `https://bwsoft.in/api/getoutlet/${outletID}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOutletName(outletResponse.data.name);
      } catch (error) {
        console.error("Error fetching inventory details:", error);
        Swal.fire("Error!", "Failed to fetch inventory details.", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchInventoryDetails();
  }, [id, location.search]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation for quantity
    if (parseInt(quantity) <= 0) {
      Swal.fire("Error!", "Quantity must be a positive number.", "error");
      return;
    }

    const inventoryData = {
      product: parseInt(productId), // Pass product ID as a number
      outlet: parseInt(outletId), // Pass outlet ID as a number
      qty: parseInt(quantity), // Quantity as a number
    };

    const token = localStorage.getItem("token");

    try {
      await axios.put(
        `https://bwsoft.in/api/inventory/update/${id}/`,
        inventoryData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      Swal.fire("Success!", "Inventory updated successfully.", "success");
    } catch (error) {
      console.error("Error updating inventory:", error);
      if (error.response && error.response.data.message) {
        Swal.fire("Error!", error.response.data.message, "error");
      } else {
        Swal.fire("Error!", "Failed to update inventory.", "error");
      }
    }
  };

  return (
    <div className="container mt-4" id="tabelcontent">
      <h2>Update Inventory</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Form onSubmit={handleSubmit}>
          {/* Product Name */}
          <Form.Group controlId="formProductName">
            <Form.Label>Product Name</Form.Label>
            <Form.Control type="text" value={productName} readOnly />
          </Form.Group>

          {/* Outlet Name */}
          <Form.Group controlId="formOutletName">
            <Form.Label>Outlet Name</Form.Label>
            <Form.Control type="text" value={outletName} readOnly />
          </Form.Group>

          {/* Quantity */}
          <Form.Group controlId="formQuantity">
            <Form.Label>Quantity *</Form.Label>
            <Form.Control
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              placeholder="Enter Quantity"
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Update Inventory
          </Button>
        </Form>
      )}
    </div>
  );
};

export default UpdateInventory;
