import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Table,
  Spinner,
  Dropdown,
  Col,
  Row,
  DropdownButton,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import * as XLSX from "xlsx"; // For Excel export

const SalesReport = () => {
  const [outlets, setOutlets] = useState([]);
  const [paidByOptions, setPaidByOptions] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [selectedPaidBy, setSelectedPaidBy] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [visibleColumns, setVisibleColumns] = useState({
    orderId: true,
    dateTime: true,
    paymentMethod: true,
    outlet: true,
    customer: true,
    totalItems: true,
    subtotal: true,
    discount: true,
    grandTotal: true,
  });
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchOutletsAndPaidByOptions = async () => {
      const token = localStorage.getItem("token");
      try {
        const [outletsResponse, paidByResponse] = await Promise.all([
          axios.get("https://bwsoft.in/api/outletlist/", {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get("https://bwsoft.in/api/paymentlist/", {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);
        setOutlets(outletsResponse.data);
        setPaidByOptions(paidByResponse.data);
      } catch (error) {
        console.error("Error fetching outlets or payment methods:", error);
        Swal.fire(
          "Error!",
          "Failed to fetch outlets or payment methods.",
          "error"
        );
      }
    };

    fetchOutletsAndPaidByOptions();
  }, []);

  const handleSearch = async () => {
    setLoading(true);
    setError("");

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("https://bwsoft.in/api/getAllOrders/", {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          outlet: selectedOutlet,
          paid_by: selectedPaidBy,
          start_date: startDate,
          end_date: endDate,
        },
      });
      setSalesData(response.data);
    } catch (error) {
      console.error("Error fetching sales data:", error);
      setError("Failed to fetch sales data.");
    } finally {
      setLoading(false);
    }
  };

  const calculateTotals = () => {
    const totalSub = salesData.reduce(
      (sum, sale) => sum + parseFloat(sale.subtotal || 0),
      0
    );
    const totalDiscount = salesData.reduce(
      (sum, sale) => sum + parseFloat(sale.discount_total || 0),
      0
    );
    const grandTotal = totalSub - totalDiscount;

    return { totalSub, totalDiscount, grandTotal };
  };

  const { totalSub, totalDiscount, grandTotal } = calculateTotals();

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toString() !== "Invalid Date" ? date.toLocaleString() : "N/A";
  };

  const toggleColumn = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const handleExportToExcel = () => {
    const filteredData = salesData.map((sale) => ({
      OrderID: sale.id,
      DateTime: formatDate(sale.created_at),
      PaymentMethod: sale.payment_method_name,
      Outlet: sale.outlet_name,
      Customer: sale.customer_name,
      TotalItems: sale.total_items,
      Subtotal: parseFloat(sale.subtotal).toFixed(2),
      Discount: parseFloat(sale.discount_total).toFixed(2),
      GrandTotal: parseFloat(sale.grand_total).toFixed(2),
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SalesData");

    // Export to Excel
    XLSX.writeFile(workbook, "Sales_Report.xlsx");
  };

  const filteredSalesData = salesData.filter((sale) =>
    searchTerm
      ? sale.customer_name.toLowerCase().includes(searchTerm.toLowerCase())
      : true
  );

  return (
    <div className="container mt-4" id="tabelcontent">
      <h2>Sales Report</h2>

      {/* <Form>
        <Form.Group controlId="formOutlet">
          <Form.Label>Choose Outlet *</Form.Label>
          <Form.Control
            as="select"
            value={selectedOutlet}
            onChange={(e) => setSelectedOutlet(e.target.value)}
            required
          >
            <option value="">-- Select Outlet --</option>
            <option value="all">All</option>
            {outlets.map((outlet) => (
              <option key={outlet.id} value={outlet.id}>
                {outlet.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formPaidBy">
          <Form.Label>Choose Paid By *</Form.Label>
          <Form.Control
            as="select"
            value={selectedPaidBy}
            onChange={(e) => setSelectedPaidBy(e.target.value)}
            required
          >
            <option value="">-- Select Payment Method --</option>
            <option value="all">All</option>
            {paidByOptions.map((method) => (
              <option key={method.id} value={method.id}>
                {method.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formDateRange">
          <Form.Label>Start Date *</Form.Label>
          <Form.Control
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
          />
          <Form.Label>End Date *</Form.Label>
          <Form.Control
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="primary" onClick={handleSearch}>
          Get Report
        </Button>
      </Form> */}
      <Form>
        <Row className="mb-3">
          <Col md={3} xs={12}>
            <Form.Group controlId="formOutlet">
              <Form.Label>Choose Outlet *</Form.Label>
              <Form.Control
                as="select"
                value={selectedOutlet}
                onChange={(e) => setSelectedOutlet(e.target.value)}
                required
              >
                <option value="">-- Select Outlet --</option>
                <option value="all">All</option>
                {outlets.map((outlet) => (
                  <option key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={3} xs={12}>
            <Form.Group controlId="formPaidBy">
              <Form.Label>Choose Paid By *</Form.Label>
              <Form.Control
                as="select"
                value={selectedPaidBy}
                onChange={(e) => setSelectedPaidBy(e.target.value)}
                required
              >
                <option value="">-- Select Payment Method --</option>
                <option value="all">All</option>
                {paidByOptions.map((method) => (
                  <option key={method.id} value={method.id}>
                    {method.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={2} xs={12}>
            <Form.Group controlId="formDateRange">
              <Form.Label>Start Date *</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={2} xs={12}>
            <Form.Group controlId="formDateRange">
              <Form.Label>End Date *</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col
            md={2}
            xs={12}
            className="d-flex justify-content-center  align-items-center"
          >
            {" "}
            <div className="d-flex justify-content-center excelbtn  align-items-flexend mt-3">
              <Button variant="primary" onClick={handleSearch}>
                Get Report
              </Button>
            </div>
          </Col>
        </Row>

        {/* New Row for the Button */}
        <Row className="mb-3"></Row>
      </Form>

      <div className="mb-3">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
          <Form.Control
            type="search"
            placeholder="Search by Customer..."
            className="mb-2 mb-md-0 me-md-2 flex-fill"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <div className="d-flex align-items-center mb-2 mb-md-0">
            <DropdownButton
              id="dropdown-basic-button"
              title="Column Filters"
              variant="success"
              className="me-2"
            >
              {Object.keys(visibleColumns).map((column) => (
                <Form.Check
                  key={column}
                  type="checkbox"
                  label={column.replace(/([A-Z])/g, " $1").trim()} // Format column name
                  checked={visibleColumns[column]}
                  onChange={() => toggleColumn(column)}
                />
              ))}
            </DropdownButton>

            <Button
              variant="success"
              className="excelbtn"
              onClick={handleExportToExcel}
            >
              Export to Excel
            </Button>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" />
          <p>Loading...</p>
        </div>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <Table striped bordered hover responsive className="mt-4">
            <thead>
              <tr>
                {visibleColumns.orderId && <th>Order ID</th>}
                {visibleColumns.dateTime && <th>Date & Time</th>}
                {visibleColumns.paymentMethod && <th>Payment Method</th>}
                {visibleColumns.outlet && <th>Outlet</th>}
                {visibleColumns.customer && <th>Customer</th>}
                {visibleColumns.totalItems && <th>Total Items</th>}
                {visibleColumns.subtotal && <th>Subtotal (INR)</th>}
                {visibleColumns.discount && <th>Discount (INR)</th>}
                {visibleColumns.grandTotal && <th>Grand Total (INR)</th>}
              </tr>
            </thead>
            <tbody>
              {filteredSalesData.length > 0 ? (
                filteredSalesData.map((sale) => (
                  <tr key={sale.id}>
                    {visibleColumns.orderId && <td>{sale.id}</td>}
                    {visibleColumns.dateTime && (
                      <td>{formatDate(sale.created_at)}</td>
                    )}
                    {visibleColumns.paymentMethod && (
                      <td>{sale.payment_method_name}</td>
                    )}
                    {visibleColumns.outlet && <td>{sale.outlet_name}</td>}
                    {visibleColumns.customer && <td>{sale.customer_name}</td>}
                    {visibleColumns.totalItems && <td>{sale.total_items}</td>}
                    {visibleColumns.subtotal && (
                      <td>{parseFloat(sale.subtotal).toFixed(2)}</td>
                    )}
                    {visibleColumns.discount && (
                      <td>{parseFloat(sale.discount_total).toFixed(2)}</td>
                    )}
                    {visibleColumns.grandTotal && (
                      <td>{parseFloat(sale.grand_total).toFixed(2)}</td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center">
                    No Records Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="totals">
            <h5>Totals</h5>
            <p>Subtotal (INR): {totalSub.toFixed(2)}</p>
            <p>Total Discount (INR): {totalDiscount.toFixed(2)}</p>
            <p>Grand Total (INR): {grandTotal.toFixed(2)}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default SalesReport;
