import React, { useEffect, useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap"; // Import necessary components
import Swal from "sweetalert2"; // For alert dialogs
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { useParams, useNavigate } from "react-router-dom"; // For routing

const EditPayMethod = () => {
  const { id } = useParams(); // Get the payment method ID from URL
  const navigate = useNavigate(); // For navigation
  const [paymentMethod, setPaymentMethod] = useState({
    name: "",
    status: true,
  });
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchPaymentMethod = async () => {
      const token = localStorage.getItem("token"); // Retrieve access token

      try {
        const response = await fetch(
          `https://bwsoft.in/api/payment/update/${id}/`, // Fetch specific payment method
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok)
          throw new Error("Failed to fetch payment method data");

        const data = await response.json();
        setPaymentMethod(data); // Set the fetched payment method data
      } catch (error) {
        console.error("Error fetching payment method:", error);
        Swal.fire("Error!", "Failed to fetch payment method data.", "error");
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchPaymentMethod(); // Call the function to fetch payment method data
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentMethod({
      ...paymentMethod,
      [name]: name === "status" ? value === "true" : value, // Convert string to boolean for status
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token"); // Retrieve access token

    try {
      const response = await fetch(
        `https://bwsoft.in/api/payment/update/${id}/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentMethod), // Send updated payment method data
        }
      );

      if (response.ok) {
        Swal.fire(
          "Success!",
          "Payment method updated successfully.",
          "success"
        );
        navigate("/payment-methods"); // Redirect after successful update
      } else {
        throw new Error("Failed to update payment method");
      }
    } catch (error) {
      console.error("Error updating payment method:", error);
      Swal.fire("Error!", "Failed to update payment method.", "error");
    }
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" /> Loading payment method data...
      </div>
    );
  }

  return (
    <div className="container mt-4" id="tabelcontent">
      <h1>Edit Payment Method</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name *</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={paymentMethod.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formStatus">
          <Form.Label>Status *</Form.Label>
          <Form.Select
            name="status"
            value={paymentMethod.status.toString()} // Convert boolean to string for the select value
            onChange={handleChange}
            required
          >
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </Form.Select>
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3">
          Update Payment Method
        </Button>
      </Form>
    </div>
  );
};

export default EditPayMethod;
