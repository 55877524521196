import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
import { Modal, Typography, Box } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GoX } from "react-icons/go";

// Styles for the modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
const Createbill = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  // Function to format the date
  const formatDate = (date) => {
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  const { orderDetails, selectedAccount } = location.state || {};

  useEffect(() => {
    const fetchSettings = async () => {
      const token = localStorage.getItem("token");

      if (!selectedAccount) {
        console.error("Selected account is not available");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `https://bwsoft.in/api/settinglist/${selectedAccount}/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch settings");

        const data = await response.json();
        setSettings(data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, [selectedAccount]);

  // Conditionally render the order details only if available
  if (!orderDetails) {
    return <div>No order details available.</div>;
  }

  // Format currency function
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="container mt-4" id="tabelcontent">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Invoice</h1>
        <Button variant="primary" onClick={handlePrint}>
          Print
        </Button>
      </div>

      <div id="print-area">
        {loading ? (
          <p>Loading settings...</p>
        ) : settings ? (
          <div className="mb-4 text-center">
            <h2>{settings.name}</h2>
            <h6>{settings.address}</h6>
            <p>Phone:{settings.mobile} 9677667225</p>
          </div>
        ) : (
          <p>No settings available for this account.</p>
        )}
        <div className="d-flex justify-content-between align-items-center">
          <h5>GSTIN :</h5>
          <h5>Email :</h5>
        </div>
        <hr></hr>
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <p>Issued to</p>
            <h5>name</h5>
            <p>Tamil Nadu, India.</p>
            <p>Mobile : 9677667225</p>
            <p>POS : Tamil Nadu</p>
          </div>
          <div>
            <p>Shipping Address </p>
            <h5>{orderDetails.customer_name}</h5>
            <p>Tamil Nadu, India.</p>
            <p>Mobile : 9677667225</p>
          </div>

          <div className=" create-bill">
            <h5 className="mb-4">CASH BILL</h5>
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-start ">
                <p className="mb-1 billcreate-left">Invoice No</p>
                <p className="billcreate-leftdot">:</p>
                <p className="mb-1 justify-content-start">
                  <strong>{orderDetails.id}</strong>
                </p>
              </div>
              <div className="d-flex justify-content-start ">
                <p className="mb-1 justify-content-start billcreate-left">
                  Date
                </p>
                <p className="billcreate-leftdot">:</p>
                <p className="mb-1 justify-content-start">
                  <strong onClick={() => setIsCalendarOpen(true)}>
                    {formatDate(selectedDate)}
                  </strong>
                </p>

                {/* Material-UI Modal for the DatePicker */}
                <Modal
                  open={isCalendarOpen}
                  onClose={() => setIsCalendarOpen(false)}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                >
                  <Box sx={style}>
                    <div className="d-flex justify-content-between">
                      <Typography id="modal-title" variant="h6" component="h2">
                        Select a Date
                      </Typography>
                      <Button
                        variant="danger"
                        onClick={() => setIsCalendarOpen(false)}
                        sx={{ mt: 2 }}
                      >
                        <GoX />
                      </Button>
                    </div>
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => {
                        setSelectedDate(date);
                        setIsCalendarOpen(false); // Close the modal on date selection
                      }}
                      dateFormat="dd/MM/yyyy"
                      todayButton="Today"
                      inline
                      style={{ width: "100%" }}
                    />
                  </Box>
                </Modal>
              </div>
              <div className="d-flex justify-content-start ">
                <p className="mb-1 billcreate-left">Payment Mode</p>
                <p className="billcreate-leftdot">:</p>
                <p className="mb-1 d-flex justify-content-start ">
                  <strong>Cash</strong>
                </p>
              </div>
              <div className="d-flex justify-content-start ">
                <p className="mb-1 billcreate-left">Sales Rep</p>
                <p className="billcreate-leftdot">:</p>
                <p className="mb-1 justify-content-start">
                  <strong>JOHN</strong>
                </p>
              </div>
              <div className="d-flex justify-content-start ">
                <p className="mb-1 billcreate-left">Mobile</p>
                <p className="billcreate-leftdot">:</p>
                <p className="mb-1 justify-content-start">
                  <strong>6374214013</strong>
                </p>
              </div>
            </div>
          </div>
        </div>

        <p>Branch ID: {selectedAccount}</p>

        <div className="mt-4">
          <Table
            striped
            bordered
            hover
            className="bill-table"
            style={{ margin: 0, padding: 0 }}
          >
            {/* Order Details Section */}
            <thead className="table-primary">
              <tr>
                <th colSpan="6" className="text-center">
                  Order Details
                </th>
              </tr>
              <tr>
                <th>#</th>
                <th>Product Name</th>
                <th>HSN Code</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody className="transparent-bg">
              {orderDetails.order_items.length > 0 ? (
                orderDetails.order_items.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.product_name}</td>
                    <td>{item.product_code}</td>
                    <td>{item.qty}</td>
                    <td>{formatCurrency(item.retail_price)}</td>
                    <td>{formatCurrency(item.retail_price * item.qty)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No items in this order.
                  </td>
                </tr>
              )}
            </tbody>

            {/* Bank Details Section */}
            <thead className="table-primary">
              <tr>
                <th colSpan="3" className="text-center">
                  Bank Details
                </th>
                <th
                  colSpan="3"
                  rowSpan="2"
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Summary
                </th>
              </tr>
              <tr>
                <th>Account Holder</th>
                <th>Details</th>
                <th>UPI</th>
              </tr>
            </thead>
            <tbody>
              {settings ? (
                <>
                  <tr>
                    <td>Account Holder</td>
                    <td>{settings.account_details.account_holder}</td>
                    <td rowSpan={4} className="text-center">
                      {settings.account_details.bar_code ? (
                        <img
                          src={settings.account_details.bar_code}
                          alt="Bar Code"
                          style={{ width: "150px" }}
                        />
                      ) : (
                        <p>No image available</p>
                      )}
                    </td>
                    <td
                      colSpan="2"
                      rowSpan={2}
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      Total Amount
                    </td>
                    <td
                      rowSpan={2}
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {formatCurrency(orderDetails.grand_total)}
                    </td>
                  </tr>
                  <tr>
                    <td>Account No</td>
                    <td>{settings.account_details.account_no}</td>
                  </tr>
                  <tr>
                    <td>IFSC Code</td>
                    <td>{settings.account_details.ifsc_code}</td>
                    <td
                      colSpan="2"
                      rowSpan={2}
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      Paid Amount
                    </td>
                    <td
                      rowSpan={2}
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {formatCurrency(orderDetails.paid_amount)}
                    </td>
                  </tr>
                  <tr>
                    <td>Bank</td>
                    <td>{settings.account_details.bank}</td>
                  </tr>
                  <tr>
                    <td>Branch</td>
                    <td>{settings.account_details.branch}</td>
                    <td>UPI ID : {settings.account_details.upi_id}</td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      Return Change
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {formatCurrency(orderDetails.return_change)}
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan={3} className="text-center">
                    No bank details available for this account.
                  </td>
                </tr>
              )}
            </tbody>

            {/* Tax Details Section */}
            <thead className="table-primary">
              <tr>
                <th
                  rowSpan="2"
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    padding: "10px",
                  }}
                >
                  Tax %
                </th>
                <th
                  rowSpan="2"
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    padding: "10px",
                  }}
                >
                  Taxable Value
                </th>
                <th
                  colSpan="2"
                  style={{
                    textAlign: "center",
                  }}
                >
                  SGST
                </th>
                <th
                  colSpan="2"
                  style={{
                    textAlign: "center",
                  }}
                >
                  CGST
                </th>
              </tr>
              <tr>
                <th>TaxRate</th>
                <th>Amount</th>
                <th>TaxRate</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>GST 18%</td>
                <td>1,372.88</td>
                <td>9.00%</td>
                <td>123.56</td>
                <td>9.00%</td>
                <td>123.56</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>
                  <strong>1,372.88</strong>
                </td>
                <td colSpan="2">
                  <strong>123.56</strong>
                </td>
                <td colSpan="2">
                  <strong>123.56</strong>
                </td>
              </tr>
            </tbody>
          </Table>
          <div>
            <p className="d-flex justify-content-end align-items-start">
              For Lucky Traders
            </p>
          </div>
          <div
            className="d-flex  align-items-end justify-content-between "
            style={{ padding: "70px 0" }}
          >
            <p>Customer Signature</p>

            <p> Vignesh Prepared By</p>
            <p>Authorized Signature</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Createbill;
