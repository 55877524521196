import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames"; // For easier class management

const Sidenav = ({ isOpen, onSidebarToggle }) => {
  const [openSections, setOpenSections] = useState({});

  const handleToggle = useCallback((section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  }, []);

  const handleSidebarClick = (e) => {
    e.stopPropagation(); // Prevent click from closing the sidebar
  };

  // Abstract Collapsible Item
  const CollapsibleItem = ({ title, iconClass, isOpen, toggleOpen, items }) => (
    <li className="nav-item">
      <Link
        className="nav-link collapsed"
        to="#"
        onClick={(e) => {
          e.preventDefault(); // Prevent default anchor behavior
          toggleOpen();
        }}
        aria-expanded={isOpen ? "true" : "false"}
      >
        <i className={`fas fa-fw ${iconClass}`}></i>
        <span>{title}</span>
      </Link>
      <div className={classNames("collapse", { show: isOpen })}>
        <div className="bg-white py-2 collapse-inner rounded">
          {items.map((item, index) => (
            <Link
              key={index}
              className="collapse-item"
              to={item.path}
              onClick={() => {
                toggleOpen(); // Close the section when an item is clicked
              }}
            >
              {item.label}
            </Link>
          ))}
        </div>
      </div>
    </li>
  );

  return (
    <div
      className={`sidenav ${isOpen ? "open" : "closed"}`}
      onClick={handleSidebarClick}
    >
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="sidenavscroll"
      >
        <Link
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to="/Home"
        >
          {/* <div className="sidebar-brand-text mx-3">Lucky Traders</div> */}
          {/* <img src="../../public/assets/img/logo.jpeg"></img> */}
          <div className="sidebar-brand-img">
            <img src="./favicon.jpeg" alt="img"></img>
          </div>
        </Link>

        <hr className="sidebar-divider my-0" />

        <li className="nav-item active">
          <Link className="nav-link" to="/Home">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Interface</div>

        {/* Category Section */}
        <CollapsibleItem
          title="Category"
          iconClass="fa-cog"
          isOpen={openSections.category}
          toggleOpen={() => handleToggle("category")}
          items={[
            { label: "Manage Category", path: "/ManageCategory" },
            { label: "Add Category", path: "/Addcategory" },
          ]}
        />

        {/* Products Section */}
        <CollapsibleItem
          title="Products"
          iconClass="fa-wrench"
          isOpen={openSections.products}
          toggleOpen={() => handleToggle("products")}
          items={[
            { label: "Manage Products", path: "/Manageproduct" },
            { label: "Add Products", path: "/Addproduct" },
            { label: "All Products Label", path: "/Allproductlabel" },
          ]}
        />

        {/* Customers Section */}
        <CollapsibleItem
          title="Customers"
          iconClass="fa-users"
          isOpen={openSections.customers}
          toggleOpen={() => handleToggle("customers")}
          items={[
            { label: "Manage Customers", path: "/ManageCustomer" },
            { label: "Add Customers", path: "/Addcustomer" },
          ]}
        />

        {/* Inventory Section */}
        <CollapsibleItem
          title="Inventory"
          iconClass="fa-box"
          isOpen={openSections.inventory}
          toggleOpen={() => handleToggle("inventory")}
          items={[
            { label: "Manage Inventory", path: "/ManageInventory" },
            { label: "Add Inventory", path: "/AddInventory" },
          ]}
        />

        {/* Bill Section */}
        <CollapsibleItem
          title="Bill"
          iconClass="fa-file-invoice-dollar"
          isOpen={openSections.bill}
          toggleOpen={() => handleToggle("bill")}
          items={[
            { label: "Billing", path: "/Bill" },
            { label: "Quotation", path: "/Quoatation" },
          ]}
        />

        {/* Reports Section */}
        <CollapsibleItem
          title="Reports"
          iconClass="fa-chart-line"
          isOpen={openSections.reports}
          toggleOpen={() => handleToggle("reports")}
          items={[
            { label: "Sales Reports", path: "/Salesreport" },
            { label: "Return Reports", path: "/Returnreport" },
          ]}
        />

        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Addons</div>

        {/* Settings Section */}
        <CollapsibleItem
          title="Settings"
          iconClass="fa-cog"
          isOpen={openSections.settings}
          toggleOpen={() => handleToggle("settings")}
          items={[
            { label: "Outlets", path: "/Outlets" },
            { label: "Users", path: "/Users" },
            { label: "Suppliers", path: "/Suppliers" },
            { label: "System Settings", path: "/Systemsettings" },
            { label: "Payment Methods", path: "/PaymentMethods" },
            { label: "Payment Createbill", path: "/Createbill" },
          ]}
        />

        <li className="nav-item">
          <Link className="nav-link" to="/charts">
            <i className="fas fa-fw fa-chart-area"></i>
            <span>Charts</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/tables">
            <i className="fas fa-fw fa-table"></i>
            <span>Tables</span>
          </Link>
        </li>

        <hr className="sidebar-divider d-none d-md-block" />

        <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            onClick={onSidebarToggle}
          ></button>
        </div>
      </ul>
    </div>
  );
};

export default Sidenav;
